import { SchoolService, BaseService } from "@/services/school.service";

const ResourceService = {
    getAll () {
        return SchoolService.getAll("resources")
    },

    add(payload) {
    	return SchoolService.create("resources", payload)
    },

    delete(result) {
    	return SchoolService.delete("resources", result.identifier, null)
    },
     
}

export { ResourceService };