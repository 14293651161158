<template>
  <div class="documents">
 <header class="inner-head">
      <div class="flex">
        <h2 class="inner-head-title">Resources</h2>

        <ul class="subnav">
               <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue">

             <button
              v-bind:class="checkTab(1)"
              v-on:click="showDocuments(1, [])"
   class="focus:outline-none"            >
                PE Passport
            </button>
          </li>
               <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
              <button
              v-bind:class="checkTab(2)"
              v-on:click="showDocuments(2, ['AFPE Provided'])"
   class="focus:outline-none"            >
                AFPE Provided
            </button>
          </li>
       <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
              <button
              v-bind:class="checkTab(3)"
               v-on:click="showDocuments(3, ['Swim England Provided'])"
   class="focus:outline-none"            >
                 Swim England
            </button>
          </li>
          <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
            <button
              v-bind:class="checkTab(4)"
               v-on:click="showDocuments(4, ['Webinar'])"
   class=" focus:outline-none"            >
                Webinar
            </button>
          </li>

       <li v-if="hasResourceModule"  
       class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
             <button
              v-bind:class="checkTab(5)"
              v-on:click="showSchoolDocuments(5)"
   class="side-list-item focus:outline-none">
               {{schoolName}} General Documents
            </button>
          </li>
        </ul>

              <ul class="text-sm pl-1 pt-1">
              <li v-if="isSchoolOwned">
          <button
            type="button"
            class="action-button icon hover:bg-blue-300 fa-plus"
            @click="addNew()"
          ></button>
        </li>
              </ul>
      </div>
    </header>


      <p class="m-8 text-gray-500">
        {{ orderedDocuments.length }} documents in view
      </p>

    <table
      class="
        w-95p
        mx-auto
        mb-2
        h-12
        pt-2
        align-text-bottom
        bg-white
        rounded
        text-left text-gray-400
      "
    >
      <thead>
        <tr>
          <th width="4%"></th>
          <th width="20%">Section</th>
          <th width="45%">Title</th>
          <th width="22%">Date</th>
          <th width="10%" align="left">Action</th>
        </tr>
      </thead>
    </table>

    <table class="table-wrapper w-95p bg-white mx-auto rounded shadow-sm">
      <tbody>
        <tr v-for="(item, index) in orderedDocuments" :key="index">
          <td @click="displayResource(item)" class="cursor-pointer" width="3%">
            <i class="icon fas" v-bind:class="showIcon(item)"></i>
          </td>
          <td @click="displayResource(item)" class="cursor-pointer" width="20%">{{ item.section }}</td>
          <td @click="displayResource(item)" class="cursor-pointer" width="45%">{{ item.title }}</td>
          <td  @click="displayResource(item)">{{ item.date }}</td>
          <td
            width="11%"
            class="text-xs cursor-pointer pl-4 text-blue-400"
            align="left"
          >

          <template  v-if="currentUser.isSubscriber && isSchoolOwned">
            <i class="fas fa-ellipsis-h ml-4 hover:opacity-75" @click="selectToDelete(item)"></i>

            <transition enter-active-class="transition duration-300 ease-out transform" 
            nter-class="-translate-y-3 scale-95 opacity-0" enter-to-class="translate-y-0 scale-100 opacity-100" 
            leave-active-class="transition duration-150 ease-in transform" leave-class="translate-y-0 opacity-100" leave-to-class="-translate-y-3 opacity-0">
      <div v-show="showDelete(item)" class="absolute pt-2">
        <div class="relative py-1 bg-white border border-gray-200 rounded-md shadow-xl">
          <div class="absolute top-0 w-4 h-4 origin-center transform rotate-45 translate-x-5 -translate-y-2 bg-white border-t border-l border-gray-200 rounded-sm pointer-events-none"></div>
          <div class="relative">
            <a class="block w-full px-4 py-2 font-medium 
            text-gray-700 whitespace-no-wrap hover:bg-gray-100 focus:outline-none 
            hover:text-gray-900 focus:text-gray-900 focus:shadow-outline transition duration-300 ease-in-out"
            @click="displayResource(item)"
              >View</a
            >
            <a @click="deleteResource(item)" class="block w-full px-4 py-2 font-medium 
            text-red-500 whitespace-no-wrap hover:bg-gray-100 focus:outline-none 
            hover:text-gray-900 focus:text-gray-900 focus:shadow-outline transition duration-300 ease-in-out">Delete</a>
             <a @click="cancelSelect()" class="block w-full px-4 py-2 font-medium 
            text-blue-500 whitespace-no-wrap hover:bg-gray-100 focus:outline-none 
            hover:text-gray-900 focus:text-gray-900 focus:shadow-outline transition duration-300 ease-in-out">Cancel</a>
     </div>
        </div>
      </div>
    </transition>
          </template>
          <template v-else >
            <a class="ml-4"
            @click="displayResource(item)"
              >View</a>
           </template>

            <i v-if="!currentUser.isSubscriber" class="fas fa-lock text-yellow-500 text-lg"></i>
          </td>
        </tr>
      </tbody>
    </table>

    <modalfile
      :url="loadFilePath()"
      :title="selectedDocument.title"
      @close="showModalFile = false"
      v-if="selectedDocument != undefined && showModalFile"
      v-show="showModalFile"
    ></modalfile>

    <modalvideo
      :url="loadVideoPath()"
      @close="showModalVideo = false"
      v-if="selectedDocument != undefined && showModalVideo"
      v-show="showModalVideo"
    ></modalvideo>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Docs from "../assets/usefuldocs.json";
import { ResourceService } from "@/services/resource.service";
import { fileResourceMixin } from "@/mixins/file-ResourceMixin.js";

const modalfile = () => import("@/components/ModalFile.vue");
const modalvideo = () => import("@/components/ModalVideo.vue");
const defaultCat = ['PE Passport', 'Social Distance Activities', 'Skills and Knowledge Progression']

export default {
  name: "documents",
  mixins: [fileResourceMixin],
  props: {

  },
  computed: {
        ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    orderedDocuments() {
      var vm = this;
      var types = vm.documentTypes.length > 0 ? vm.documentTypes : defaultCat;

      if (vm.isSchoolOwned) {
        return this.$orderBy(vm.schoolResources, ["title"]);
      } else {
        var filtered = vm.Docs.filter(function (v) {
          return types.includes(v.section);
        });

        return this.$orderBy(filtered, ["title.String"]);
      }
    },
    circle() {
      return this.completedPercentage; //((this.completedPercentage / 100) * 100 * Math.PI) + ',9999';
    },
    hasFile() {
      return this.file.name != undefined && this.file.name.length > 3;
    },
    hasResourceModule() {
      return this.currentAccess.resourceAccess
    },
  },
  data() {
    return {
      Docs,
      selectedDocument: {},
      showModalFile: false,
      showModalVideo: false,
      showModalAddition: false,
      schoolResources: [],
      file: {},
      title: "",
      isMenuVisible:false,
      currentTab: 1,
      documentTypes: [],
      isSchoolOwned: false
    };
  },
  created() {
    this.loading = true;
    this.fetchSchoolResources();
  },
  mounted() {},
  methods: {
    fetchSchoolResources() {
      ResourceService.getAll()
        .then((response) => (this.schoolResources = response.data))
        .catch((err) => {
          console.log("resources " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    showIcon(document) {
      var name = this.isSchoolOwned ? document.url : document.file;
      let fileExt = name.split(".").pop();
      if (fileExt == "doc") {
        return "fa-file-word";
      } else if (fileExt == "pdf") {
        return "fa-file-pdf";
      } else if (["png", "jpg", "gif"].includes(fileExt)) {
        return "fa-file-image";
      } else {
        return "fa-file-video";
      }
    },
    displayResource(document) {
      this.selectedDocument = document;
      this.isMenuVisible = false
      if (document.type == "video") {
        this.showModalVideo = true;
      } else {
        this.showModalFile = true;
      }
    },
    loadFilePath() {
      return this.isSchoolOwned ? this.selectedDocument.url : "useful/" + this.selectedDocument.file;
    },
    loadVideoPath() {
      return this.selectedDocument.file;
    },
    addNew() {
      this.showModalAddition = true;
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      console.log(this.file);
    },
    selectToDelete(document) {
      this.selectedDocument = document
      this.isMenuVisible = true
    },
    showDelete(document) {
      return this.isMenuVisible === true && this.selectedDocument.identifier === document.identifier
    },
    cancelSelect() {
      this.isMenuVisible = false
      this.selectedDocument.identifier = undefined
    },
    showDocuments(tab, cat) {
      this.typeSelected = 'documents'
      this.currentTab = tab;

      this.documentTypes = cat
      this.isSchoolOwned = false
    },
    showSchoolDocuments(tab) {
      this.typeSelected = 'documents'
      this.currentTab = tab;
      this.isSchoolOwned = true
    },
    setStyles(value) {
      return this.typeSelected === value
        ? "bg-blue-600 text-white"
        : "bg-white text-gray-400";
    },
    checkTab(value) {
      return this.currentTab == value
        ? "active"
        : "";
    },
  },
  components: {
    modalfile,
    modalvideo,
  },
};
</script>

<style>
i.icon {
  font-size: 18px;
  padding-left: 4px;
  padding-top: 4px;
}

.progress-circle {
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  transform: scaleX(-1) rotate(-55deg);
}

.progress-circle__percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-circle__container {
  display: inline-block;
  position: relative;
}

.progress-circle__path {
  transition: 0.5s ease-in-out all;
}

.container {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (min-width: 500px) {
  .container {
    flex-direction: row;
  }

  .progress-circle__button {
    margin-top: 0;
  }
}
</style>


